<template>
  <div class="">
      <headers />
   <div class="mb row" style="padding:15px;padding-top: 70px !important;">
      
      <div class="col-lg-12">
            <div class="search-result-box card-box">
             <div class="drop_area mg-b-20 disFlex align-center hor-center"  id="drop_areas" :style="{'border-color': (borderhover ? '#3d8cff':'#BBBBBB')}">
                    <div  style=""><div class="lang" v-html="lan.drag_or_drop_here"></div></div>
                    <div>
                      <div class="font-blue pos-r cur-hand mg-l-10" style="top: 12px;"><i class="el-icon-upload mg-r-5"></i><div class="lang" v-html="lan.upload_file"></div></div>
                    </div>
                     <input type="file" accept="application/xlsx" id="uploadfile" class="input-upload cur-hand"  @change ="uploadFile($event.target.files)" />
                  </div>
              <div class="fileHtml" v-if="fileData.length>0">
                    <ul class="filelist">
                      <li v-for = "(item,index) in fileData"  :key="index" class="mg-b-20">
                          <div class="file-card">
                          <!--  <p>  <img v-bind:src="item.Url" alt="" style="width: 300px;height: 300px;"></p>-->
                            <div class="disFlex align-center">
                              <div style="display: inline-block;float: left;"><i class="el-icon-document mg-r-10"></i>{{item.fileText}}</div>
                              <div class="mg-l-30 flex text-right mg-r-20"><span class="mg-l-15 font-blue cur-hand" @click="deleteFile(item,index)">删除</span></div>
                            </div>
                        </div>
                      <!-- <p><el-progress :percentage=item.progressStatl  status="success"></el-progress></p>-->
                      </li>
                    </ul>
                  </div>
            <div class="embed-responsive-16by9 tab-pane show active tc1" id="tc1" v-if="curindex=='tc1'">
                <div class="card-box mb-2" style="position:relative">
                <!-- map -->
                <div class="address_list" v-if="mapList.length>0"> 
                  <div v-bind:class="[cad=='k'+idNum ? 'active line':'line']" v-for="(obj, idNum) in mapList" @click="gotowindow(idNum)" v-bind:key="idNum">
                  <b> {{(obj['事業所']==undefined ?'未命名':obj['事業所'])}}</b><br>
                    <p>{{"社名 ："+(obj.Corp_Name==undefined ?'':obj.Corp_Name)}} <br>{{"住所： "+obj.address+""}}</p>
                  </div>
                </div>
                <div id="googleMap" class="google"></div>
              </div>
            </div>
        
        </div>
      </div>  
    </div>

  </div>
</template>

<script>

import Headers from '@/components/Headers.vue'
import router from '@/router'
import axios from 'axios';
import { inject } from 'vue';
//import { MarkerClusterer } from "@googlemaps/markerclusterer";
export default {
  name: "search_result",
   components: {
    Headers
  },
  data() {
    const { currentRoute } = router
    return {
      center: { lat: Number, lng: Number },
      address: '',
      distance:20000,
      type:currentRoute.value.query.type==undefined ? "company":currentRoute.value.query.type,
      mapList:[],
      reload: 0,
      cad:'',
      map:null,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          MapTypeId: {},
          Marker:{},
          InfoWindow:{},
          Circle:{},
        },
      },
      markers:[],
      infowindow:[],
      curindex:"tc1",
      tableHeaders:["Office",'Corp Name',"Address","Distance"],
      // company
       viewParams: {
        name: 'CorpReference',
        apiUrl: '/v1/corps_reference/',
        articleView: 'CorpReferenceArticle',
        articleParamId: 'id',
        dataType:100,
      },
      offset: 0,
      listStrage: {
        query:'',
        limit: 100,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object]
      },
      tableHeaders2: [],
      listData: [],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: false,
      downloadAlert: '',
      
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      cur_lan:'jp',
       suc_msg:"",
      error_msg:"",
      filepond:{},
      tag_input:'',
    
      fileData:[],
      finfo:{
        tags:[],
        name:'',
        remark:'',
        fid:1},
      borderhover:false,
    };
  },
  mounted() {
   
    var _this=this;
     setTimeout(function(){
          var dropbox = document.getElementById('drop_areas');
            console.log(dropbox)
            dropbox.addEventListener("drop",this.enentDrop,false)
            dropbox.addEventListener("dragleave",function () {
              
              _this.borderhover =  false;
            })
            dropbox.addEventListener("dragenter",function () {
            
              _this.borderhover =  true;
            })
            dropbox.addEventListener("dragover",function () {
            
              _this.borderhover =  true
            })
        },1500);
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
    this.loadGoogleMapsScript().then(() => {
        this.google = window.google;
      });
  },
  methods: {
     enentDrop: function(e){
        this.borderhover = false
        e.stopPropagation();
        e.preventDefault();  //必填字段
        let fileData = e.dataTransfer.files;
        console.log(fileData);
        this.uploadFile(fileData)
      },
      uploadFile: function (e){   //渲染上传文件
      var file=e;
      console.log(file);
        for (let i = 0; i !== file.length; i++) {
          let fileJson = {
            Url:'',
            progressStatl:0,
            fileText:'',
          };
          let video_type= file[i].name.indexOf(".xls")>0;
           //如果是图片
            let fileurl = window.URL.createObjectURL(file[i]); //创建一个url连接,供src属性引用
            fileJson.Url = fileurl;
          if(!video_type){
              alert("Can not support file,only xls")
              return false;
             
          }
          fileJson.fileText = file[i].name;
          this.finfo.name=file[i].name;
   
          this.fileData=[fileJson];
          this.filepond=file[0];
          this.SubmitFile();
        }
      },
   deleteFile:function (index){   //删除已选文件
          this.fileData.splice(index,1);
          this.filepond=null;
          document.getElementById('uploadfile').value=null;
      },
     toggleDDMenu: function() {
      this.ddMenu = !this.ddMenu
    },
    allCheck: function() {
      this.checkKeys = this.tableHeaders2
    },
    allUncheck: function() {
      this.checkKeys = []
    },
    toggleFocus: function(idNum) {
      this.isFocus = idNum;
    },
    SubmitFile:function (){
     
      let param = new FormData(); // 创建form对象
      if(this.fid==0){
      if(this.filepond==null || this.filepond.name==undefined ){
          this.error_msg="Pleace upload file"
          return false;
        }
      }
     
      param.append('filepond', this.filepond); 
      param.append('id_token', localStorage.getItem('id_token'));
      this.isLoading=true;
      var that=this;
      axios.post(process.env.VUE_APP_API2_URL_BASE+'/uploadAddressByXlsx', param,{headers:{'Content-Type': 'multipart/form-data'}})
      .then(function (response) {
        that.isLoading=false;
            console.log(response);
            if(response.data.status){
              that.mapList=response.data.data;
              that.initializeMap();
            }else{
              that.suc_msg="";
              alert(response.data.msg);
            }
      })
      .catch(function (error) {
            console.log(error);
      });
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=geometry,places";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
    initializeMap() {
      const mapContainer = this.$el.querySelector("#googleMap");
      
      const { Map, MapTypeId, Marker,InfoWindow} = this.google.maps;
      const Geocoder = new this.google.maps.Geocoder();
      var _that=this;
      this.cad='';
      // map create
      var zoom=7
      if(_that.distance>3000000){
        zoom=3
      }else if(_that.distance>2000000){
        zoom=4
      }else if(_that.distance>1000000){
        zoom=5
      }else if(_that.distance>500000){
        zoom=6
      }else if(_that.distance>200000){
        zoom=7
      }else if(_that.distance>100000){
        zoom=8
      }else if(_that.distance>50000){
        zoom=9
      }else if(_that.distance>20000){
        zoom=10
      }else if(_that.distance>10000){
        zoom=11
      }else if(_that.distance>5000){
        zoom=12
      }else if(_that.distance>2000){
        zoom=13
      }else if(_that.distance>1000){
        zoom=14
      }else if(_that.distance>500){
        zoom=15
      }else if(_that.distance>200){
        zoom=16
      }else if(_that.distance>100){
        zoom=17
      }else if(_that.distance>50){
        zoom=18
      }else if(_that.distance>20){
        zoom=19
      }else{
        zoom=20;
      }
      _that.map = new Map(mapContainer, {
        zoom: zoom,
        mapTypeId: MapTypeId.ROADMAP,
      });

    
      
      new Geocoder.geocode({ address: _that.mapList[0].address }, function (results, status) {
        if (status === "OK" && results[0]) {
          let locate = results[0].geometry.location;
          let center = {
            lat: Number(locate.lat()),
            lng: Number(locate.lng()),
          };

          // set locate
          _that.map.setCenter(center);
         
          _that.markers=[];
          _that.infowindow=[];
          if(_that.mapList.length>0){
            var image="https://cbredata.s3.ap-northeast-1.amazonaws.com/images/lou.png";
            _that.mapList.map((obj,i) => {
               _that.infowindow[i] = new InfoWindow({
                content: '<div id="content">' +
                            '<div id="siteNotice">' +
                            "</div>" +
                            '<h3 id="firstHeading" class="firstHeading">'+(obj.Corp_Name==undefined ?'':obj.Corp_Name)+'</h3>' +
                            '<div id="bodyContent">' +
                            "<p>事業所 ："+(obj['事業所']==undefined ?'未命名':obj['事業所'])+"<br>住所： "+obj.address+"<br>延床： "+obj['延床']+"<br>フロア： "+obj['フロア']+"</p>" +
                            
                            "</div>" +
                            "</div>"
              });
              
              _that.markers[i]=new Marker({
                position: {
                          lat: parseFloat(obj.latitude),
                          lng: parseFloat(obj.longitude)},
                map:_that.map,
                title: obj.Corp_Name==undefined ?'':obj.Corp_Name,
                icon: image,
              });
              _that.markers[i].addListener("click", () => {
              _that.infowindow[i].open({
                anchor: _that.markers[i],
                map:_that.map,
                shouldFocus: false
              });
            });

            });
            
           
          }  
          //pano.setPosition(_that.center);
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    },
  
    gotowindow(i){
      var _that=this;
      if(this.cad!=''){
        _that.infowindow[this.cad.replace('k','')].close();
      }
      this.cad='k'+i;
      _that.infowindow[i].open({
        anchor: _that.markers[i],
        map:_that.map,
        shouldFocus: false
      });
    }
  },
  watch: {

    $route(){
      
    },
    
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.google {
  height: 800px;
}
.overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  opacity: 0.8;
  background: #4477aa;
  border: solid 3px #336699;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #333;
  text-shadow: 1px 1px 1px #666;
  padding: 0 4px;
}
.overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #336699;
}
.overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #336699;
}
.row {
  font-size: 14px;
}
pre {
  font-family: 'Ubuntu Mono';
  font-size: 14px;
}
.row {
  zoom: 1;
  margin-left: -20px;
}
.row:before,
.row:after {
  display: table;
  content: "";
  zoom: 1;
}
.row:after {
  clear: both;
}
.row>[class*="span"] {
  display: inline;
  float: left;
  margin-left: 20px;
}
.span1 {
  width: 40px;
}
.span2 {
  width: 100px;
}
.span3 {
  width: 160px;
}
.span4 {
  width: 220px;
}
.span5 {
  width: 280px;
}
.span6 {
  width: 340px;
}
.span7 {
  width: 400px;
}
.span8 {
  width: 460px;
}
.span9 {
  width: 520px;
}
.span10 {
  width: 580px;
}
.span11 {
  width: 640px;
}
.span12 {
  width: 700px;
}
.span13 {
  width: 760px;
}
.span14 {
  width: 820px;
}
.span15 {
  width: 880px;
}
.span16 {
  width: 940px;
}
.span17 {
  width: 1000px;
}
.span18 {
  width: 1060px;
}
.span19 {
  width: 1120px;
}
.span20 {
  width: 1180px;
}
.span21 {
  width: 1240px;
}
.span22 {
  width: 1300px;
}
.span23 {
  width: 1360px;
}
.span24 {
  width: 1420px;
}
pre.prettyprint {
  background: #fff;
  border-color: #c1c1c1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px #999;
  -moz-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
  padding: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.label {
  font-size: 10.998px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999999;
  margin: 2px 6px;
}
.label {
  padding: 1px 4px 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.notice {
  background-color: #3a87ad;
}
form input[type=text] {
  border: solid 1px #999999;
  padding: 2px;
}
.edit_marker {
  width: 250px;
  height: 80px;
}
.edit_marker p {
  margin: 0;
}
.address_list {position:absolute; top:80px;left:30px;z-index: 999;background: #FFF;max-height: 500px;overflow-y: auto;}
.address_list .line{border-top:1px solid #eee;padding: 5px;margin:0 5px;     cursor: pointer;}
.address_list .line:nth-child(0){
  border-top:0;
}
.address_list .active{background: #003f2d; color:#fff;}
.nav-tabs li{cursor: pointer;}

.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: .75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: .15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -.5rem;
  top: -.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .dropdown-menu.show li {
    flex-basis: 50%;
  }

}

@media screen and (max-width: 500px) {

  .dropdown-menu.show li {
    flex-basis: 100%;
  }

}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover, table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: .35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
.table td .table-data-container{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}
.types{display: table;margin: 0 auto;}
.drop_area{width: 100%;
    text-align: center;
    position: relative;
    display: inline-block;
    height: 150px; 
    line-height: 150px;
    border: 1px #ced4da dashed;}
    .drop_area div{display: inline-block;}
    .input-upload{position:absolute;top:0;left:0;opacity: 0;width: 100%;height: 100%;}
    .font-blue{color: #012a2d;}
    .cur-hand{cursor: pointer;}
    .filelist{
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-top: 10px;
    }
    .filelist li{list-style: none;}
</style>
